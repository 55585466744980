/**
 * Main app js file
 *
 * @copyright Copyright Maneko
 * @author    Maneko
*/
jQuery(document).ready(function ($) {
    'use strict';

    var $swiperContainer = $('.swiper-container'),
        $date = $('.date'),
        $apartmentsBookBtn = $('main.apartments .book-btn');

    /**
    * Initialize Swiper.
    *
    * @return void
    */
    function initSwiper()
    {
        new Swiper($swiperContainer, {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            loop: true,
            speed: 600,
            grabCursor: true,
            observer: true,
            autoplay: 8000,
        });
    }

    /**
    * Initialize Bootstrap-datepicker.
    *
    * @return void
    */
    function initDatepicker()
    {
        $date.datepicker({
            startDate: '+d',
            format: 'dd-mm-yyyy',
        });
    }

    /**
    * On book btn clicked
    *
    * @return void
    */
    function onBookBtnClicked(event)
    {
        var url = BOOKING_URL,
            checkIn = $('#check-in').val(),
            checkOut = $('#check-out').val(),
            persons = $('#num-persons').val(),
            code = $('#promo-code').val();

        event.preventDefault();

        checkIn = '&check_in_date=' + checkIn;
        checkOut = '&check_out_date=' + checkOut;
        persons = '&number_adults=' + persons;
        code = '&promotion_code=' + code;

        url += checkIn + checkOut + persons + code;

        window.open(url,'_blank');
    }

    /**
     * Set events.
     *
     * @return void
     */
    function setEvents()
    {
        $apartmentsBookBtn.click(onBookBtnClicked);
    }

    /**
     * Initialize method.
     *
     * @return void
     */
    function init()
    {
        initSwiper();
        initDatepicker();

        setEvents();
    }

    // Initialize
    init();
});
